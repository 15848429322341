@font-face {
  font-family: 'Euclid';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/euclid/euclidcirculara-regular.woff2') format('woff2'), url('./fonts/euclid/euclidcirculara-regular.woff') format('woff');
}
@font-face {
  font-family: 'Euclid';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./fonts/euclid/euclidcirculara-medium.woff2') format('woff2'), url('./fonts/euclid/euclidcirculara-medium.woff') format('woff');
}

body {
  font-family: InterVariable, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.wrapper {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}